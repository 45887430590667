(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.common.components.imagebutton.viewer.skins.ImageButtonSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        null,
        [
          "_correct-positioning"
        ],
        {},
        [
          "div",
          "defaultImage",
          [],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_correct-positioning"
        ],
        {},
        [
          "div",
          "hoverImage",
          [],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_correct-positioning"
        ],
        {},
        [
          "div",
          "activeImage",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "defaultImage": {
      "skin": "skins.core.ImageNewSkin"
    },
    "hoverImage": {
      "skin": "skins.core.ImageNewSkin"
    },
    "activeImage": {
      "skin": "skins.core.ImageNewSkin"
    }
  },
  "params": {
    "fade_next": "TRANSITION",
    "fade_prev": "TRANSITION"
  },
  "paramsDefaults": {
    "fade_next": "opacity 0.1s ease 0s",
    "fade_prev": "opacity 0.5s ease 0s"
  },
  "css": {
    "%link": "position:relative;display:block;z-index:0;overflow:visible;-ms-touch-action:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%link:after": "content:\"\";position:absolute;z-index:5;width:100%;height:100%;display:block;",
    "%defaultImage": "position:absolute;opacity:1;",
    "%hoverImage": "position:absolute;opacity:0;",
    "%activeImage": "position:absolute;opacity:0;",
    "%_correct-positioning": "position:absolute;",
    "%[data-state~=\"hovered\"] %defaultImage": "opacity:0;",
    "%[data-state~=\"hovered\"] %hoverImage": "opacity:1;",
    "%[data-state~=\"hovered\"] %activeImage": "opacity:0;",
    "%[data-state~=\"pressed\"] %defaultImage": "opacity:0;",
    "%[data-state~=\"pressed\"] %hoverImage": "opacity:0;",
    "%[data-state~=\"pressed\"] %activeImage": "opacity:1;",
    "%[data-state~=\"transition_fade\"][data-state~=\"prepare_adh\"] %defaultImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_ahd\"] %hoverImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_dah\"] %activeImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_dha\"] %hoverImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_had\"] %activeImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_hda\"] %defaultImage": "z-index:1;[fade_next]",
    "%[data-state~=\"transition_fade\"][data-state~=\"prepare_adh\"] %activeImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_ahd\"] %activeImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_dah\"] %defaultImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_dha\"] %defaultImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_had\"] %hoverImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_hda\"] %hoverImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_adh\"] %hoverImage": "z-index:3;[fade_prev]",
    "%[data-state~=\"transition_fade\"][data-state~=\"prepare_ahd\"] %defaultImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_dah\"] %hoverImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_dha\"] %activeImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_had\"] %defaultImage,%[data-state~=\"transition_fade\"][data-state~=\"prepare_hda\"] %activeImage": "z-index:2;[fade_prev]"
  }
}

        return skins;
    }));